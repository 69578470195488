import { Component } from '@angular/core';
import { BaseCancellationComponent } from '@shared/base-cancellation/base-cancellation.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss']
})
export class TestingComponent {
  ref: DynamicDialogRef | undefined;

  constructor(private _dialogService: DialogService){ }
  
  openModal() {
    const config: DynamicDialogConfig = { 
      data: { 
        title: '¿Quieres anular la compra?', 
        subtitle: 'Al anular la compra, el pago no se realizará y no habrá ningún cargo a tu cuenta.',
        confirmLabel: 'Sí, anular compra'
      }, 
      contentStyle: { overflow: 'auto' },
      closable: false,
      closeOnEscape: false,
      showHeader: false,
    }
    // this.ref = this._dialogService.open(BaseErrorComponent, config);
    this.ref = this._dialogService.open(BaseCancellationComponent, config);
  }
}
