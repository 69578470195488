import { Component, OnInit } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeCL from '@angular/common/locales/es-CL';
import { Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';

registerLocaleData(localeCL, 'cl');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('600ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  // @HostBinding('@.disabled') // Decorator for disabling animations, maybe useful when in DEV mode.
  title = 'copec-payment-auth';

  constructor(private router: Router) { }

  ngOnInit() {
    const queryString = window.location.search; // Get the query string including the "?"
    const transacId = queryString.substring(1).trim(); // Remove the leading "?"
    if (transacId && typeof transacId !== 'undefined') { 
      return this.router.navigate(['reading-qr', transacId]); 
    } 
    return this.router.navigate(['error'], { state: { showButton: false } });
  }
}
