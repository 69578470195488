<ng-template #readingQR>
  <div *ngIf="!isMobile" class="qr-container">
    <qrcode [qrdata]="qrCode" [width]="200" [errorCorrectionLevel]="'H'"></qrcode>
  </div>
  <div *ngIf="isMobile" class="qr-container">
    <img class="waiting-image" [src]="'../assets/svg/waiting-auth.svg'" alt="waiting-auth-logo">
  </div>
  <div class="sale-code-container">
    <div class="rounded-container">
      <div class="code-text">
        Código de compra: <span class="code-number"> {{qrCode}} </span>
      </div>
    </div>
  </div>
  <div class="container-title">
    <div class="text-big-01"> {{ title }} </div>
  </div>
  <div class="sale-detail-container">
    <div class="detail-line">
      <div>Comercio</div>
      <div class="detail-text">{{businessName}}</div>
    </div>
    <div class="detail-line">
      <div>Monto</div>
      <div class="detail-text">{{amount | currency: 'CLP': 'symbol-narrow': '.0-1': 'cl'}}</div>
    </div>
  </div>
  <div *ngIf="!isMobile" class="info-container">
    <div>
      <img class="waiting-image" [src]="'../assets/svg/info-icon.svg'" alt="info-icon">
    </div>
    <div class="info-text">También puedes autorizar accediendo a la notificación que enviamos a tu teléfono.</div>
  </div>
  <div class="button-container">
    <div *ngIf="isMobile && !canUseDeeplink" class="notification-container">
      <div class="notification-title"> ¿No te llegó la notificación?
      </div>
      <div>
        <div class="notification-instruction">
          Copia este código e ingrésalo manualmente en <span class="special-text"> Copec Pay </span>
        </div>
        <div style="height: 20px;"></div>
        <div class="notification-path">
          Tarjeta digital > Escanear > Ingresa el código manualmente
        </div>
      </div>
      <div style="height: 10px;"></div>
      <div class="copy-toclipboard-container">
        <div class="code-label">
          Código de compra: <span class="code-value"> {{qrCode}} </span>
        </div>
        <p-button icon="pi pi-copy" [text]="true" [plain]="true" styleClass="p-button-outlined"
          (click)="copyToClipboard()">
          <span style="font-weight: 500;"> Copiar <div style="width: 5px;"></div> </span>
        </p-button>
      </div>
    </div>
    <p-button label="Anular" [text]="true" [plain]="true" (onClick)="onCancelSale()"
      styleClass="p-button-anular"></p-button>
    <p-button *ngIf="isMobile && canUseDeeplink" [label]="buttonMobileTxt" styleClass="p-button-rounded"
      (click)="onPressMobile()"></p-button>
    <!-- <div *ngIf="isMobile" style="height: 10px;"></div> -->
    <!-- <a *ngIf="isMobile" [href]="deepLink" (onclick)="onPressDeeplink1">
      link _self
    </a> -->
  </div>
</ng-template>
<ng-template #codeGenerator>
  <div class="generate-code-container">
    <!-- <img *ngIf="!isMobile" class="generate-qr-img" [src]="'../assets/svg/QR.svg'" alt="generate-code"> -->
    <!-- <img *ngIf="!isMobile" class="welcome-logo-mobile" [src]="'../assets/svg/activacion.svg'" alt="copec-logo-welcome"> -->
    <img class="welcome-logo-mobile" [src]="'../assets/svg/activacion.svg'" alt="copec-logo-welcome">
  </div>
  <div class="container-title">
    <div *ngIf="!isMobile" class="title">
      {{titleCodeGenerator}}
    </div>
    <div *ngIf="isMobile" class="text-big-01">
      {{titleCodeGenerator}}
    </div>
  </div>

  <div *ngIf="isMobile" class="subtitle-mobile-case">
    Autoriza el pago desde tu teléfono celular
  </div>

  <div *ngIf="!isMobile" class="subtitle-desktop">
    ¡Recuerda! Deberás autorizar el pago a través de la app Copec.
  </div>
  <!-- <div *ngIf="!isMobile" class="sale-detail-container">
    <div class="detail-line">
      <div>Comercio</div>
      <div class="detail-text">{{businessName}}</div>
    </div>
    <div class="detail-line">
      <div>Monto</div>
      <div class="detail-text">{{amount | currency: 'CLP': 'symbol-narrow': '.0-1': 'cl'}}</div>
    </div>
  </div> -->
  <div *ngIf="!isMobile" style="height: 100px;"></div>
  <div *ngIf="isMobile" style="height: 25vh;"></div>
  <div class="button_container">
    <p-button [label]="txtBtnCodeGenerator" [styleClass]="isMobile ? 'p-button-rounded': 'p-button-large'"
      (onClick)="onGenerateCode()" [rounded]="true"></p-button>
  </div>
</ng-template>
<div *ngIf="isLoading; else mainModal" class="central-container" style="height: 298.5px;">
  <app-custom-spinner></app-custom-spinner>
</div>
<ng-template #mainModal>
  <app-main-modal [templateRef]="isCodeGenerated ? readingQR : codeGenerator" [@fadeInOut]></app-main-modal>
</ng-template>