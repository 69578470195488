import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReadingQrComponent } from './modules/payment-gateway/reading-qr/reading-qr.component';
import { BaseErrorComponent } from '@shared/base-error/base-error.component';
import { WaitingAuthScreenComponent } from './modules/payment-gateway/waiting-auth-screen/waiting-auth-screen.component';
import { AuthSuccessComponent } from './modules/payment-gateway/auth-success/auth-success.component';
import { TestingComponent } from './modules/payment-gateway/testing/testing.component';

const routes: Routes = [ 
  {
    path: 'reading-qr/:id_transaction',
    component: ReadingQrComponent,
    title: 'Copec Pay - Scanning QR',
    loadChildren: () => import('./modules/payment-gateway/payment-gateway.module').then((m) => m.PaymentGatewayModule) 
  },
  {
    path: 'waiting-auth',
    component: WaitingAuthScreenComponent,
    title: 'Copec Pay - Esperando autorización',
    // canActivate: [authGuard]
  },
  {
    path: 'auth-success',
    component: AuthSuccessComponent,
    title: 'Copec Pay - Autorización exitosa',
    // canActivate: [authGuard]
  },
  {
    path: 'testing-miscelanous',
    component: TestingComponent,
    title: 'Testing',
  },
  { 
    path: 'error', 
    component: BaseErrorComponent, 
    title: 'Copec Pay - Error',
  },
  { 
    path: 'timeout', 
    component: BaseErrorComponent, 
    title: 'Copec Pay - Tiempo de espera excedido',
    data: {
      isError: false,
      iconName: 'timeout-image',
      titleText: 'Excediste el tiempo máximo',
      subtitleText: 'Por seguridad anulamos tu compra.',
    }  
  },
  { 
    path: 'cancellation', 
    component: BaseErrorComponent, 
    title: 'Copec Pay - Anulación compra',
    data: {
      isError: false,
      iconName: 'cancellation-image',
      titleText: 'Compra anulada',
      subtitleText: 'Ningún cargo fue realizado a tu cuenta.',
    }  
  },
  { 
    path: '', 
    redirectTo: '/error', 
    pathMatch: 'full', 
    title: 'Copec Pay - Página no encontrada'
  },
  { 
    path: '**', 
    component: BaseErrorComponent, 
    title: 'Copec Pay - Página no encontrada',
    data: {
      isError: true,
      titleText: 'Página no encontrada',
      subtitleText: 'La página que buscas no existe.',
      showButton: false
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
