import { trigger, transition, style, animate } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { GlobalConfigService } from '@core/services/global-config.service';


@Component({
  selector: 'app-auth-success',
  templateUrl: './auth-success.component.html',
  styleUrls: ['./auth-success.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('600ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AuthSuccessComponent implements OnInit, OnDestroy {
  alertIcon: SafeHtml | undefined;
  @Input() businessName = "BUSINESS-NAME";
  @Input() amount = 0;
  @Input() redirectURL = '';
  @Input() tbkToken = '';

  private successSubscription: Subscription = new Subscription();


  constructor(private activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer, private globalConfig: GlobalConfigService, private _router: Router, private location: Location) {
    console.debug("Constructor called...");
    // Fetch the custom SVG file and sanitize it
    fetch('/assets/svg/alert-circle.svg')
      .then((response) => response.text())
      .then((svgData) => {
        this.alertIcon = this.sanitizer.bypassSecurityTrustHtml(svgData);
      });
  }

  ngOnInit(): void {
    this.settingRouteParameters();
  }

  ngOnDestroy(): void {
    this.successSubscription.unsubscribe();
  }

  settingRouteParameters() {
    // Pasar parametros desde queryParams (URL)
    this.activatedRoute.queryParams.forEach((param) => {
      if (param['businessName']) { this.businessName = param['businessName']; }
      if (param['amount']) { this.amount = param['amount']; }
      if (param['redirectURL']) { this.redirectURL = param['redirectURL']; }
      if (param['tbkToken']) { this.tbkToken = param['tbkToken']; }
    });

    // Pasar parametros por la propiedad "data" en la definicion de rutas.
    this.activatedRoute.data.forEach(param => {
      if (param['businessName']) { this.businessName = param['businessName']; }
      if (param['amount']) { this.amount = param['amount']; }
      if (param['redirectURL']) { this.redirectURL = param['redirectURL']; }
      if (param['tbkToken']) { this.tbkToken = param['tbkToken']; }
    });

    // Usando history.state para evitar pasarlos por URL.
    if (history.state) {
      const state = history.state;
      if (state['businessName']) { this.businessName = state['businessName']; }
      if (state['amount']) { this.amount = state['amount']; }
      if (state['redirectURL']) { this.redirectURL = state['redirectURL']; }
      if (state['tbkToken']) { this.tbkToken = state['tbkToken']; }
    }
  }

  exitApp(): void {
    window.location.replace(this.redirectURL + "?TBK_TOKEN=" + this.tbkToken);
  }
}
