<img src="/assets/svg/cancellation-image.svg" alt="pre-load-cancellation" style="display: none;">
<img src="/assets/svg/error-icon.svg" alt="pre-load-cancellation" style="display: none;">
<img src="/assets/svg/timeout-image.svg" alt="pre-load-cancellation" style="display: none;">
<img src="/assets/svg/waiting-auth.svg" alt="pre-load-cancellation" style="display: none;">
<img src="/assets/svg/generate-qr.svg" alt="pre-load-cancellation" style="display: none;">
<img src="/assets/svg/info-icon.svg" alt="pre-load-cancellation" style="display: none;">
<div [@fadeInOut] class="background-wrapper">
  <header class="header-container">
    <img src="assets/svg/copecpay-logo.svg" alt="copecpay-logo">
    <img src="assets/svg/webpay-logo.svg" alt="webpay-logo">
  </header>
  <router-outlet [@fadeInOut]></router-outlet>
</div>