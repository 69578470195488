import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizationResponse } from '@core/models/authorization.model';
import { Observable } from 'rxjs/internal/Observable';
import { GlobalConfigService } from './global-config.service';

@Injectable({
  providedIn: 'root'
})
export class VerifySaleAuthService {
  constructor(private http: HttpClient, private globalConfig: GlobalConfigService) { }

  // TODO: api URL declararla en variables de entorno en pipeline
  private enableTesting = this.globalConfig.enableTestingFlow();
  private apiURL = this.enableTesting ? 'http://localhost:3000/response' : '/auth/autenticacion';
  private api_key = "";

  // TODO: api key declararla en variables de entorno en pipeline
  private getHeaders(): HttpHeaders {
    if (window.location.host.substring(0, 2) == "de") {
      this.api_key = 'fmToLP4da82NGykXuaGOIRIZhH2FVlO8hbXKn8V7'
    } else if (window.location.host.substring(0, 2) == "qa") {
      this.api_key = 'rJzYDVCYJU51NIixGxnnp5muz00WphgZ7G41rMQy'
    } else {
      this.api_key = 'nQoPB5sPjg3ms6NF9wMDj4pGNUFgUU1q1PYWMsEG'
    }

    return new HttpHeaders({
      // Add any other headers you need here
      'Content-Type': 'application/json',
      'x-api-key': this.api_key
    });
  }

  getSaleAuth(transactId: string): Observable<AuthorizationResponse> {
    const body = {
      transaccion_id: transactId,
      canal: "WEB", //[ “WEB”, “APP” ]
      metodo: "GET", // [ “GET”, “POST” ]
      envio_push: "false",
    }
    return this.enableTesting ? this.http.get<AuthorizationResponse>(this.apiURL) : this.http.post<AuthorizationResponse>(this.apiURL, body, { headers: this.getHeaders() });
  }

  pushNotification(transactId: string): Observable<unknown> {
    const body = {
      transaccion_id: transactId,
      canal: "WEB", //[ “WEB”, “APP” ]
      metodo: "GET", // [ “GET”, “POST” ]
      envio_push: "true",
    }
    return this.enableTesting ? this.http.get<AuthorizationResponse>(this.apiURL) : this.http.post<AuthorizationResponse>(this.apiURL, body, { headers: this.getHeaders() });
  }

  saleCancellation(trx_id: string, transactionId: string): Observable<AuthorizationResponse> {
    const body = {
      id_transaccion_auth: transactionId,
      transaccion_id: trx_id,
      canal: "WEB", //[ “WEB”, “APP” ]
      metodo: "POST", // [ “GET”, “POST” ]
      estado_autenticacion: "ANULADA",
    }
    return this.http.post<AuthorizationResponse>(this.apiURL, body, { headers: this.getHeaders() });
  }
}
