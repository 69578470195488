import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-custom-spinner',
  templateUrl: './custom-spinner.component.html',
  styleUrls: ['./custom-spinner.component.scss']
})
export class CustomSpinnerComponent {
  customSpinnerSVG: SafeHtml | undefined;

  constructor(private sanitizer: DomSanitizer) {
    fetch('/assets/svg/base-loader.svg')
      .then((response) => response.text())
      .then((svgData) => {
        this.customSpinnerSVG = this.sanitizer.bypassSecurityTrustHtml(svgData);
      });
  }
}
