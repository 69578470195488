import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { EMPTY, catchError, takeWhile, Subscription, interval, switchMap, Observable, throwError } from 'rxjs';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SaleStates } from '@core/enums/sale-states.enum';
import { AuthorizationResponse } from '@core/models/authorization.model';
import { GlobalConfigService } from '@core/services/global-config.service';
import { VerifySaleAuthService } from '@core/services/verify-sale-auth.service';
import { BaseCancellationComponent } from '@shared/base-cancellation/base-cancellation.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeeplinkWarningModalComponent } from '@shared/deeplink-warning-modal/deeplink-warning-modal.component';

@Component({
  selector: 'app-reading-qr',
  templateUrl: './reading-qr.component.html',
  styleUrls: ['./reading-qr.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('600ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})

export class ReadingQrComponent implements OnInit, OnDestroy {
  @Input() transactId = "NO-ID";
  @Input() qrCode = "NO-CODE";
  @Input() businessName = "BUSINESS-NAME";
  @Input() amount = 0;

  private tId = '';
  private initialSubscription: Subscription = new Subscription();
  private readingSubscription: Subscription = new Subscription();
  public deepLink = 'https://muevocopecde.page.link/tarjetasautorizacion?codigo=';

  isLoading = false;
  ref: DynamicDialogRef | undefined;
  public isMobile = false;
  public isCodeGenerated = false;
  public canUseDeeplink: boolean | undefined;
  copySuccess = false;
  copyError = false;

  public get buttonMobileTxt(): string {
    return 'Ir a Copec Pay';
  }
  public get titleCodeGenerator(): string {
    return '¡Bienvenido a Copec Pay!';
    // return !this.isMobile ? 'Genera tu código QR para autorizar la transacción' : '¡Bienvenido a Copec Pay!';
  }
  public get txtBtnCodeGenerator(): string {
    return 'Continuar';
    // return !this.isMobile ? 'Generar código' : 'Continuar';
  }
  public get title(): string {
    return !this.isMobile ? 'Abre tu app Copec y escanea este código QR' : 'Presiona la notificación que te enviamos y autoriza en Copec Pay';
  }

  constructor(private deviceService: DeviceDetectorService, public dialogService: DialogService,
    private _activatedRoute: ActivatedRoute, private _router: Router,
    private _verifySaleService: VerifySaleAuthService, private globalConfig: GlobalConfigService) {
    this._activatedRoute.params.subscribe(params => {
      if (!params['id_transaction']) {
        this._router.navigate(['error'], {
          replaceUrl: true,
          skipLocationChange: false,
          state: {
            isError: true,
            titleText: 'Página no encontrada',
            subtitleText: 'La página que buscas no existe.',
            showButton: false
          }
        });
        return false;
      }
      this.transactId = params['id_transaction'] || 'NO-CODE'
      return true;
    });
  }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.isLoading = true;

    this.initStarted().then((response) => {
      if (response.data?.tipo_acs != 'transbank') {
        this._router.navigate(['error'], {
          replaceUrl: true,
          skipLocationChange: false,
          state: {
            isError: true,
            titleText: 'Página no encontrada',
            subtitleText: 'La página que buscas no existe.',
            showButton: false
          }
        });
        if (!this.initialSubscription.closed) { this.initialSubscription.unsubscribe(); }
        return;
      }
      this.qrCode = response.data?.codigo_qr || 'NO-CODE';
      this.canUseDeeplink = response.data?.deeplink_habilitado;
      const state = response.data?.estado_transaccion;
      this.tId = response.data?.id || '';
      if (response.data?.deeplink) {
        this.deepLink = '';
        this.deepLink = response.data.deeplink;
        this.canUseDeeplink = response.data?.deeplink_habilitado;
      } else { this.deepLink += this.qrCode; }

      if (state === SaleStates.Started) {
        this.isLoading = false;
        this.businessName = response.data?.nombre_fantasia_comercio || 'NULL';
        this.amount = response.data?.monto || 0;
        this.canUseDeeplink = response.data?.deeplink_habilitado;
        // Activar polling de reading
        this.readingQR()
          .then((auth) => {
            this.afterReadingQR(auth).finally();
          }).catch(
            (err) => {
              console.error('Error readingQR: ', err);
            }
          );
      } else { this.afterReadingQR(response).finally(); }
    });
  }

  ngOnDestroy(): void {
    console.warn("leave...")
    this.initialSubscription.unsubscribe();
    this.readingSubscription.unsubscribe();
    if (this.ref) { this.ref?.close(); }
  }

  initStarted(): Promise<AuthorizationResponse> {
    // Step 1: Polling para el estado "INICIADA"
    return new Promise((resolve) => {
      this.initialSubscription = this.startPolling(1000, true).subscribe((response) => {
        this.initialSubscription.unsubscribe();
        return resolve(response);
      });
    });
  }

  readingQR(): Promise<AuthorizationResponse> {
    // Step 2: Polling para el estado "SCAN_QR"
    return new Promise((resolve) => {
      this.readingSubscription = this.startPolling(1000).subscribe((response) => {
        this.readingSubscription.unsubscribe();
        return resolve(response);
      });
    });
  }

  startPolling(pollingInterval: number, isStarting = false): Observable<AuthorizationResponse> {
    let retries = 0;
    return interval(pollingInterval)
      .pipe(
        switchMap(
          () => this._verifySaleService.getSaleAuth(this.transactId)
            .pipe(
              catchError((err): Observable<never> => {
                retries++;
                console.error('Error polling: ', err, retries);
                if (retries >= this.globalConfig.MAX_RETRIES) {
                  if (!this.initialSubscription.closed) { this.initialSubscription.unsubscribe(); }
                  if (!this.readingSubscription.closed) { this.readingSubscription.unsubscribe(); }
                  this._router.navigate(['error'], {
                    replaceUrl: true,
                    skipLocationChange: false,
                    state: {
                      isError: true,
                      showButton: false
                    }
                  });
                  return throwError(() => new Error(err));
                }
                return EMPTY;
              }),
              takeWhile((response) => {
                const state = response.data?.estado_transaccion;
                return (isStarting && state === SaleStates.Started) || state === SaleStates.Scanning || state === SaleStates.Approved || state === SaleStates.PushOk || this.errorFound(state);
              })
            )
        )
      );
  }

  errorFound(state = ''): boolean {
    if (state) { return state === SaleStates.Error || state === SaleStates.Timeout || state === SaleStates.Cancelled || state === SaleStates.NoData; }
    return true;
  }

  getPath(state = ''): string {
    return state === SaleStates.Scanning ? 'waiting-auth' : (state === SaleStates.Approved || state === SaleStates.PushOk) ? 'auth-success' : state === SaleStates.Timeout ? 'timeout' :
      state === SaleStates.Cancelled ? 'cancellation' : 'error';
  }

  onCancelSale() {
    console.warn("Deteniendo polling...");
    if (!this.readingSubscription.closed) { this.readingSubscription.unsubscribe(); }
    console.warn("Cancelando compra...")
    const config: DynamicDialogConfig = {
      data: {
        title: '¿Quieres anular la compra?',
        subtitle: 'Al anular la compra, el pago no se realizará y no habrá ningún cargo a tu cuenta.',
        confirmLabel: 'Sí, anular compra'
      },
      contentStyle: { overflow: 'auto' },
      closable: false,
      closeOnEscape: false,
      showHeader: false,
    }
    this.ref = this.dialogService.open(BaseCancellationComponent, config);
    this.ref.onClose.subscribe((canCancel) => {
      if (canCancel) {
        this.isLoading = true;
        // Mandar request de anulacion, activar LOADER y cuando finalice sin error, ir a la pantalla de anulacion.
        try {
          this._verifySaleService.saleCancellation(this.qrCode, this.tId).subscribe((cancelResponse) => {
            this.isLoading = false;
            const statusCode = cancelResponse.status_code;
            const state = cancelResponse.data?.estado_transaccion;
            console.warn('saleCancellation response: ', cancelResponse);
            const data = {
              redirectURL: cancelResponse.data?.url_callback || '',
              stateType: SaleStates.Cancelled,
              tbkToken: cancelResponse.data?.data_acs?.token_tbk
            }
            const navigationExtras: NavigationExtras = {
              replaceUrl: true,
              skipLocationChange: false,
              state: data
            };
            const isSuccess = statusCode === '200' && state === SaleStates.Cancelled;
            if (isSuccess) { this._router.navigate(['cancellation'], navigationExtras); }
            else {
              this.isLoading = false;
              console.warn('Error saleCancellation: No se anulo', cancelResponse);
              // en caso de cualquier error de anulacion, enviar a /error
              this._router.navigate(['error'], {
                replaceUrl: true,
                skipLocationChange: false,
                state: {
                  isError: true,
                  showButton: false
                }
              });
            }
          });
        } catch (error) {
          this.isLoading = false;
          console.error('Error saleCancellation: ', error);
          // en caso de cualquier error de anulacion, enviar a /error
          this._router.navigate(['error'], {
            replaceUrl: true,
            skipLocationChange: false,
            state: {
              isError: true,
              showButton: false
            }
          });
        }
      } else {
        console.warn("Resume polling...");
        this.readingQR().then((auth) => {
          this.afterReadingQR(auth).finally();
        }).catch(err => { console.error('Error readingQR: ', err); });
      }
    });
  }

  async onPressMobile() {
    try {
      // Abrir un modal para explicar caso que no funcione redireccion
      const config: DynamicDialogConfig = {
        data: {
          title: 'Autorizar transacción',
          qrCode: this.qrCode
        },
        contentStyle: { overflow: 'auto', },
        closable: true,
        closeOnEscape: true,
        showHeader: false,
        position: 'bottom',
        width: '100%',
        style: {
          'margin-bottom': '0px', 'margin-left': '0px', 'margin-right': '0px'
        }
      }
      this.ref = this.dialogService.open(DeeplinkWarningModalComponent, config);

      // Open the deep link in a new tab
      window.open(this.deepLink, '_self');
      return false;
    } catch (err) {
      console.error('Error deeplink', err);
      return false;
    }
  }

  afterReadingQR(auth: AuthorizationResponse): Promise<boolean> {
    const finalState = auth.data?.estado_transaccion;
    const path = this.getPath(finalState);
    if (finalState === SaleStates.Timeout && (!auth.data?.url_callback || typeof auth.data.url_callback === 'undefined')) {
      const data = {
        businessName: auth.data?.nombre_fantasia_comercio || 'NULL',
        amount: auth.data?.monto || 0,
        redirectURL: auth.data?.url_callback || '',
        subtitleText: 'No fue posible continuar. Por favor, intentalo nuevamente.',
        showButton: false,
        tbkToken: auth.data?.data_acs?.token_tbk
      }
      const navigationExtras: NavigationExtras = {
        replaceUrl: true,
        skipLocationChange: false,
        state: data
      };
      return this._router.navigate(['error'], navigationExtras);
    } else {
      const data = {
        businessName: auth.data?.nombre_fantasia_comercio || 'NULL',
        amount: auth.data?.monto || 0,
        qrCode: this.qrCode,
        redirectURL: auth.data?.url_callback || '',
        tId: this.tId,
        showButton: path === 'error' || !auth.data?.url_callback ? false : true,
        tbkToken: auth.data?.data_acs?.token_tbk
      }
      const navigationExtras: NavigationExtras = {
        replaceUrl: true,
        skipLocationChange: false,
        state: data
      };
      if (path === 'auth-success') {
        window.location.replace(auth.data?.url_callback + "?TBK_TOKEN=" + auth.data?.data_acs?.token_tbk);
        return new Promise(() => true);
      }
      return this._router.navigate([path], navigationExtras);
    }
  }

  /**
   * Función para "generar" codigo QR. Se hace polling hasta el estado "SCAN_QR" y 
   * se agrega una notificacion push para la app en caso web-mobile.
   */
  async onGenerateCode() {
    if (!this.isCodeGenerated) {
      this.isCodeGenerated = true;
      // TODO: Mandar notificacion PUSH 1 vez y en mobile o desktop.
      this._verifySaleService.pushNotification(this.transactId).subscribe({
        error: (err) => {
          console.error('Error onGenerateCode: ', err);
          return false;
        }
      });
      // No se activa polling. Previamente activado en ngOnInit.
    }
  }

  /**
   * Función para copiar el codigo QR. 
   * TODO: hacerla funcional dentro de un Webview es dificil debido a que dependen del software tercero.
   */
  async copyToClipboard() {
    try {
      await navigator.clipboard.writeText(this.qrCode);
      this.copySuccess = true;
      this.copyError = false;
    } catch (err) {
      console.error('Unable to copy text to clipboard', err);
      this.copySuccess = false;
      this.copyError = true;
    }
  }

  onPressDeeplink1(): boolean {
    window.open(this.deepLink, '_self');
    return false;
  }
}
