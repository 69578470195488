export enum SaleStates {
  NoData = "CANCELADA",
  Timeout = "TIMEOUT",
  Cancelled = "ANULADA",
  Error = "ERROR",
  None = "NO_ERROR",
  Scanning = "ESCANEADO",
  Approved = "APROBADA",
  Started = "INICIADA",
  PushOk = "PUSH_OK"
}