import { Location } from '@angular/common';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { GlobalConfigService } from '@core/services/global-config.service';
import { VerifySaleAuthService } from '@core/services/verify-sale-auth.service';

@Component({
  selector: 'app-base-error',
  templateUrl: './base-error.component.html',
  styleUrls: ['./base-error.component.scss']
})
export class BaseErrorComponent implements OnInit {
  @Input() isError = true;
  @Input() stateType = 'error'; // error | cancellation | timeout
  @Input() showButton = true;
  @Input() iconName = 'error-icon';
  @Input() titleText = 'Algo salió mal';
  @Input() subtitleText = 'No fue posible continuar. Verifica que los datos ingresados sean los correctos.';
  @Input() btnLabel = 'Volver a Webpay';
  @Input() redirectURL = '';
  @Input() tbkToken = '';

  private previousState: unknown = null;

  constructor(private sanitizer: DomSanitizer, private router: Router, private activatedRoute: ActivatedRoute, private globalConfig: GlobalConfigService, private location: Location, private _verifySaleService: VerifySaleAuthService) { }

  ngOnInit(): void {
    this.settingRouteParameters();
    window.onpopstate = () => {
      // Replace the URL with the external URL to which you want to redirect
      window.location.replace(this.redirectURL);
    };
  }
  
  public get isProduction(): boolean {
    return this.globalConfig.isProduction();
  }

  // Use HostListener to listen for the 'popstate' event
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    const currentState = history.state;
    if (currentState !== this.previousState) {
      // Aqui por seguridad se podria anular la compra y redireccionar hacia Webpay
      window.history.pushState(null, '', '/error');
      this.router.navigate(['/error']);
    }
    this.previousState = currentState;
  }

  settingRouteParameters(): void {
    // Pasar parametros desde queryParams (URL)
    this.activatedRoute.queryParams.forEach((param) => {
      if (!this.isUndefined(param, 'isError')) { this.isError = param['isError']; }
      if (param['titleText']) { this.titleText = param['titleText']; }
      if (param['subtitleText']) { this.subtitleText = param['subtitleText']; }
      if (param['iconName']) { this.iconName = param['iconName']; }
      if (param['redirectURL']) { this.redirectURL = param['redirectURL']; }
      if (!this.isUndefined(param, 'showButton')) { this.showButton = param['showButton']; }
      if (param['stateType']) { this.stateType = param['stateType']; }
      if (param['tbkToken']) { this.stateType = param['tbkToken']; }
    });
    // Pasar data por parametros (URL) o el parametro "data"
    this.activatedRoute.data.forEach(param => {
      if (!this.isUndefined(param, 'isError')) { this.isError = param['isError']; }
      if (param['titleText']) { this.titleText = param['titleText']; }
      if (param['subtitleText']) { this.subtitleText = param['subtitleText']; }
      if (param['iconName']) { this.iconName = param['iconName']; }
      if (param['redirectURL']) { this.redirectURL = param['redirectURL']; }
      if (!this.isUndefined(param, 'showButton')) { this.showButton = param['showButton']; }
      if (param['stateType']) { this.stateType = param['stateType']; }
      if (param['tbkToken']) { this.tbkToken = param['tbkToken']; }
    });
    // Usando history.state para evitar pasarlos por URL.
    if (history.state) {
      const state = history.state;
      if (!this.isUndefined(state, 'isError')) { this.isError = state['isError']; }
      if (state['titleText']) { this.titleText = state['titleText']; }
      if (state['subtitleText']) { this.subtitleText = state['subtitleText']; }
      if (state['iconName']) { this.iconName = state['iconName']; }
      if (state['redirectURL']) { this.redirectURL = state['redirectURL']; }
      if (!this.isUndefined(state, 'showButton')) { this.showButton = state['showButton']; }
      if (state['stateType']) { this.stateType = state['stateType']; }
      if (state['tbkToken']) { this.tbkToken = state['tbkToken']; }
    }
  }

  getIconPath(): SafeResourceUrl {
    const path = `/assets/svg/${this.iconName}.svg`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(path);
  }

  isUndefined(obj: Data, key: string){
    if (typeof obj[key] === 'undefined') { return true; }
    return false;
  }

  onPressBtn(): void { 
    window.location.replace(this.redirectURL + "?TBK_TOKEN=" + this.tbkToken);
  }
}
