<div class="deeplink-modal-container">
  <div
    style="display: flex; flex-direction: row nowrap; align-items: center; gap:1rem; justify-content: space-between; width: 100%;">
    <div style="
    color: #0C122D;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px; /* 150% */">{{title}}</div>
    <p-button icon="pi pi-times" [text]="true" [plain]="true" (onClick)="closeModal()"
      styleClass="p-button-rounded"></p-button>
  </div>
  <div>
    <div style="color: #657085; font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */">
      Si el botón “Ir a Copec Pay” NO funciona, copia este código e ingrésalo manualmente en <span style="color:#0C122D; font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;"> Copec Pay </span>
    </div>
    <div style="height: 20px;"></div>
    <div style="color:#0C122D; font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;">
      Tarjeta digital > Escanear > Ingresa el código manualmente
    </div>
  </div>
  <div style="height: 10px;"></div>
  <div style="display: flex; justify-content: start; width: 100%; align-items: center; gap: 1rem;">
    <div style="color: #657085; font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */">Código de compra:
      <span style="color:#0C122D; font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;"> {{qrCode}}
      </span>
    </div>
    <p-button icon="pi pi-copy" [text]="true" [plain]="true" styleClass="p-button-outlined" (click)="copyToClipboard()">
      <span style="font-weight: 500;"> Copiar <div style="width: 5px;"></div> </span>
    </p-button>
  </div>
</div>