import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { EMPTY, Observable, catchError, interval, switchMap, takeWhile, Subscription, throwError } from 'rxjs';
import { AuthorizationResponse } from '@core/models/authorization.model';
import { VerifySaleAuthService } from '@core/services/verify-sale-auth.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseCancellationComponent } from '@shared/base-cancellation/base-cancellation.component';
import { trigger, transition, style, animate } from '@angular/animations';
import { SaleStates } from '@core/enums/sale-states.enum';
import { GlobalConfigService } from '@core/services/global-config.service';

@Component({
  selector: 'app-waiting-auth-screen',
  templateUrl: './waiting-auth-screen.component.html',
  styleUrls: ['./waiting-auth-screen.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('600ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class WaitingAuthScreenComponent implements OnInit, OnDestroy {
  @Input() businessName = "BUSINESS-NAME";
  @Input() amount = "0";
  @Input() qrCode = '';

  private tId = '';
  private waitingSubscription: Subscription = new Subscription();
  isLoading = false;
  ref: DynamicDialogRef | undefined;

  constructor(private globalConfig: GlobalConfigService, private dialogService: DialogService,
    private _verifySaleService: VerifySaleAuthService, private _router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.settingRouteParameters();
    this.waitingAuth().then((auth) => {
      if (auth.data?.tipo_acs != 'transbank') {
        this._router.navigate(['error'], {
          replaceUrl: true,
          skipLocationChange: false,
          state: {
            isError: true,
            titleText: 'Página no encontrada',
            subtitleText: 'La página que buscas no existe.',
            showButton: false
          }
        });
        if (!this.waitingSubscription.closed) { this.waitingSubscription.unsubscribe(); }
        return;
      }
      console.log("[afterWaitingAuth]")
      this.afterWaitingAuth(auth).finally();
    }).catch(err => { console.error('Error waitingAuth: ', err); });
  }

  ngOnDestroy(): void {
    this.waitingSubscription.unsubscribe();
  }

  settingRouteParameters(): void {
    // Pasar parametros desde queryParams (URL)
    this.activatedRoute.queryParams.forEach((param) => {
      if (param['businessName']) { this.businessName = param['businessName']; }
      if (param['amount']) { this.amount = param['amount']; }
      if (param['qrCode']) { this.qrCode = param['qrCode']; }
      if (param['tId']) { this.tId = param['tId']; }
    });

    // Pasar parametros por por la propiedad "data" en la definicion de rutas.
    this.activatedRoute.data.forEach(param => {
      if (param['businessName']) { this.businessName = param['businessName']; }
      if (param['amount']) { this.amount = param['amount']; }
      if (param['qrCode']) { this.qrCode = param['qrCode']; }
      if (param['tId']) { this.tId = param['tId']; }
    });

    // Usando history.state para evitar pasarlos por URL.
    if (history.state) {
      const state = history.state;
      if (state['businessName']) { this.businessName = state['businessName']; }
      if (state['amount']) { this.amount = state['amount']; }
      if (state['qrCode']) { this.qrCode = state['qrCode']; }
      if (state['tId']) { this.tId = state['tId']; }
    }
  }

  onCancelSale() {
    console.warn("Deteniendo polling...");
    if (!this.waitingSubscription.closed) { this.waitingSubscription.unsubscribe(); }

    console.warn("Cancelando compra...");
    const config: DynamicDialogConfig = {
      data: {
        title: '¿Quieres anular la compra?',
        subtitle: 'Al anular la compra, el pago no se realizará y no habrá ningún cargo a tu cuenta.',
        confirmLabel: 'Sí, anular compra'
      },
      contentStyle: { overflow: 'auto' },
      closable: false,
      closeOnEscape: false,
      showHeader: false,
    }

    this.ref = this.dialogService.open(BaseCancellationComponent, config);
    this.ref.onClose.subscribe((canCancel) => {
      if (canCancel) {
        this.isLoading = true;
        try {
          this._verifySaleService.saleCancellation(this.qrCode, this.tId).subscribe((response) => {
            this.isLoading = false;
            console.warn('saleCancellation response: ', response);
            const data = {
              redirectURL: response.data?.url_callback || '',
              isError: false,
              iconName: 'cancellation-image',
              titleText: 'Compra anulada',
              subtitleText: 'Ningún cargo fue realizado a tu cuenta.',
              stateType: SaleStates.Cancelled,
              tbkToken: response.data?.data_acs?.token_tbk
            }
            const navigationExtras: NavigationExtras = {
              replaceUrl: true,
              skipLocationChange: false,
              state: data
            };
            if (response.status_code === '200' && response.data?.estado_transaccion === SaleStates.Cancelled) {
              this._router.navigate(['cancellation'], navigationExtras);
            } else {
              console.error('Error saleCancellation: No se anulo', response);
              // en caso de cualquier error de anulacion, enviar a /error
              this._router.navigate(['error'], {
                replaceUrl: true,
                skipLocationChange: false,
                state: {
                  isError: true,
                  showButton: false
                }
              });
            }
          });
        } catch (error) {
          this.isLoading = false;
          console.error('Error saleCancellation: ', error);
          // en caso de cualquier error de anulacion, enviar a /error
          this._router.navigate(['error'], {
            replaceUrl: true,
            skipLocationChange: false,
            state: {
              isError: true,
              showButton: false
            }
          });
        }
      } else {
        console.warn("Resume polling...");
        this.waitingAuth().then((auth) => {
          this.afterWaitingAuth(auth).finally();
        }).catch(err => { console.error('Error readingQR: ', err); });
      }
    });
  }

  waitingAuth(): Promise<AuthorizationResponse> {
    // Step 3: Polling para el estado "COMPLETE"
    return new Promise((resolve) => {
      this.waitingSubscription = this.startPolling(1000).subscribe((response) => {
        this.waitingSubscription.unsubscribe();
        return resolve(response);
      });
    });
  }

  startPolling(pollingInterval: number, isStarting = false): Observable<AuthorizationResponse> {
    let retries = 0;
    return interval(pollingInterval)
      .pipe(switchMap(() => this._verifySaleService.getSaleAuth(this.tId).pipe(
        catchError((err) => {
          retries++;
          console.error('Error polling: ', err, retries);
          if (retries >= this.globalConfig.MAX_RETRIES) {
            if (!this.waitingSubscription.closed) { this.waitingSubscription.unsubscribe(); }
            this._router.navigate(['error'], {
              replaceUrl: true,
              skipLocationChange: false,
              state: {
                isError: true,
                showButton: false
              }
            });
            return throwError(() => new Error(err));
          }
          return EMPTY;
        }),
        takeWhile((response) => {
          const state = response.data?.estado_transaccion;
          return (isStarting && state === SaleStates.Started) || state === SaleStates.Approved || state === SaleStates.PushOk || this.errorFound(state);
        })))
      );
  }

  getPath(state = '', authData: AuthorizationResponse): string {
    return state === SaleStates.Started ? 'reading-qr/' + authData.data?.codigo_qr : (state === SaleStates.Approved || state === SaleStates.PushOk) ? 'auth-success'
      : state === SaleStates.Timeout ? 'timeout' : state === SaleStates.Cancelled ? 'cancellation' : 'error';
  }

  errorFound(state = ''): boolean {
    if (state) { return state === SaleStates.Error || state === SaleStates.Timeout || state === SaleStates.Cancelled || state === SaleStates.NoData; }
    console.warn("State can't be null...");
    return true;
  }

  afterWaitingAuth(auth: AuthorizationResponse): Promise<boolean> {
    const state = auth.data?.estado_transaccion;
    const path = this.getPath(state, auth);
    if (state === SaleStates.Timeout && (!auth.data?.url_callback || typeof auth.data.url_callback === 'undefined')) {
      const data = {
        businessName: auth.data?.nombre_fantasia_comercio || 'NULL',
        amount: auth.data?.monto || "0",
        redirectURL: auth.data?.url_callback || '',
        subtitleText: 'No fue posible continuar. Por favor, intentalo nuevamente.',
        showButton: false,
        tbkToken: auth.data?.data_acs?.token_tbk
      }
      const navigationExtras: NavigationExtras = {
        replaceUrl: true,
        skipLocationChange: false,
        state: data
      };
      return this._router.navigate(['error'], navigationExtras);
    } else {
      // TODO: el flujo cambia a redireccionar a comercio directamente.
      try {
        if (path === 'cancellation' || path === 'error' || path === 'timeout') {
          const data = {
            businessName: auth.data?.nombre_fantasia_comercio || 'NULL',
            amount: auth.data?.monto || "0",
            redirectURL: auth.data?.url_callback || '',
            showButton: path === 'error' || !auth.data?.url_callback ? false : true,
            tbkToken: auth.data?.data_acs?.token_tbk
          }
          const navigationExtras: NavigationExtras = {
            replaceUrl: true,
            skipLocationChange: false,
            state: data
          };
          return this._router.navigate([path], navigationExtras);
        } else {
          window.location.replace(auth.data?.url_callback + "?TBK_TOKEN=" + auth.data?.data_acs?.token_tbk);
          return new Promise(() => true);
        }
      } catch (error) {
        console.error("Error catch afterWaitingAuth: ", error);
        return new Promise(() => false);
      }
    }
  }
}
