import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';


@Component({
  selector: 'app-base-cancellation',
  templateUrl: './base-cancellation.component.html',
  styleUrls: ['./base-cancellation.component.scss']
})
export class BaseCancellationComponent implements OnInit {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() confirmLabel = '';
  
  constructor(public ref: DynamicDialogRef, private dialogConfig: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.dialogConfig.data) {
      this.title = this.dialogConfig.data['title']
      this.subtitle = this.dialogConfig.data['subtitle']
      this.confirmLabel = this.dialogConfig.data['confirmLabel']
    }
  }

  closeModal() {
    this.ref.close(false);
  }
  
}
