<ng-template #baseErrorModal>
  <div class="base-error-container">
    <!-- Top Icon -->
    <div class="error-icon-container">
      <img [ngClass]="{'error-svg': isError, 'base-logo': !isError}" [src]="getIconPath()" alt="error-icon">
    </div>
    <!-- Title -->
    <div class="error-title">
      {{ titleText }}
    </div>
    <!-- Subtitle -->
    <div class="error-subtitle">
      {{ subtitleText }}
    </div>
    <!-- Button -->
    <div *ngIf="showButton" class="button-container">
      <p-button [label]="btnLabel" styleClass="p-button-rounded" (onClick)="onPressBtn()"></p-button>
    </div>
  </div>
</ng-template>
<app-main-modal [templateRef]="baseErrorModal"></app-main-modal>