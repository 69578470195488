import { Injectable } from '@angular/core';
import { environment } from 'enviroments/enviroment';

@Injectable({
  providedIn: 'root'
})
export class GlobalConfigService {

  // https://tiendawpay.pad.cl:4443/ (tienda comercio testing en DEV)
  // 5593910025429749 numero tarjeta testing. Prepago -> copecpay -> fecha vencimiento -> CVV (cualquiera)
  // 5593910024485155 09/28 936
  MAX_RETRIES = 60;

  isProduction(): boolean {
    return environment.production;
  }

  showTimer() {
    return false;
  }

  showTestButtons(): boolean {
    return false;
  }

  // Add more methods to access other environment variables
  enableTestingFlow(): boolean {
    return false;
  }
}
