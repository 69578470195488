import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-deeplink-warning-modal',
  templateUrl: './deeplink-warning-modal.component.html',
  styleUrls: ['./deeplink-warning-modal.component.scss']
})
export class DeeplinkWarningModalComponent implements OnInit {
  @Input() title = '';
  @Input() qrCode = '';
  copySuccess = false;
  copyError = false;
  constructor(public ref: DynamicDialogRef, private dialogConfig: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.dialogConfig.data) {
      this.title = this.dialogConfig.data['title']
      this.qrCode = this.dialogConfig.data['qrCode']
    }
  }
  
  async copyToClipboard() {
    try {
      await navigator.clipboard.writeText(this.qrCode);
      this.copySuccess = true;
      this.copyError = false;
    } catch (err) {
      console.error('Unable to copy text to clipboard', err);
      this.copySuccess = false;
      this.copyError = true;
    }
  }

  closeModal() {
    this.ref.close(false);
  }
}
