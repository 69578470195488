import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-main-modal',
  templateUrl: './main-modal.component.html',
  styleUrls: ['./main-modal.component.scss']
})
export class MainModalComponent {
  @Input()templateRef!: TemplateRef<unknown>;
}
