<ng-template #authSuccess>
  <div class="waiting-auth-container">
    <img class="waiting-image" [src]="'../assets/svg/waiting-auth.svg'" alt="waiting-auth-logo">
    <div class="loader-container">
      <div class="alert-icon" [innerHTML]="alertIcon"></div>
    </div>
  </div>
  <div class="container-title">
    <div class="text-big-01">
      Continua con Webpay para la confirmación del pago
    </div>
  </div>
  <div class="sale-detail-container">
    <div class="detail-line">
      <div>Comercio</div>
      <div class="detail-text">{{businessName}}</div>
    </div>
    <div class="detail-line">
      <div>Monto</div>
      <div class="detail-text">{{amount | currency: 'CLP': 'symbol-narrow': '3.0-1': 'cl'}}</div>
    </div>
  </div>
  <div class="button-container">
    <p-button [label]="'Continuar con Webpay'" styleClass="p-button-rounded" (onClick)="exitApp()"></p-button>
  </div>
</ng-template>
<app-main-modal [templateRef]="authSuccess" [@fadeInOut]></app-main-modal>